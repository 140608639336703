import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import img4 from '../../Images/3_in1_classic_prikaz.jpg';
import img1 from '../../Images/160316_PRESSCOFFE_2in1.jpg';
import img2 from '../../Images/160316_PRESSCOFFE_3in1_14g.jpg';
import img6 from '../../Images/140316_FRUITICA_MIXCAFFE_CHOCOHAZELNUT_3in1.jpg';
import img5 from '../../Images/140316_FRUITICA_MIXCAFFE_STRONG_3in1.jpg';
import img7 from '../../Images/kesica2_in_1_RGB_za_net.png';
import img8 from '../../Images/kesica_3_in_1_RGB_za_net.png';
import img3 from '../../Images/kesica_2_in1_zaproveru.jpg';
import img10 from '../../Images/Gomex_PL_2u1_3u1_instant_kafa_tog.png';
import img9 from '../../Images/1-1_Instant_kafa_Korigovan_predlog_resenja.jpg';
import img11 from '../../Images/Bellissimo coffee drinks.png';
import img12 from '../../Images/IMG-28519e9d5bebf28f57b0e084c28bcb42-V.jpg';
import img13 from '../../Images/IMG-ab84bfe42c81a0726d7265423780616a-V.jpg';
import img14 from '../../Images/Norbi Update Low Carb kafa.jpg';
import img15 from '../../Images/Norbi Update Low Carb kakaoitalpor.jpg';
import img16 from '../../Images/Bas Bas 2u1 - 1.jpg';
import img17 from '../../Images/Bas Bas 3u1 - 1.jpg';
import img18 from '../../Images/MIXCAFE_BLACK & WHITE_STICK 2.jpg';
import img19 from '../../Images/Bas Bas Instant kafa 1.jpg';
import img20 from '../../Images/K plus 2in1.jpg';
import img21 from '../../Images/K plus 3in1.jpg';

const styles = theme => ({
	paperTitle: {
		...theme.mixins.gutters(),
		paddingTop: 12,
		paddingBottom: 12,
		background: '#90482E',
		marginBottom: 14,
	},
	paperImg1: {
		height: 200,
		padding: 10,
		display: 'flex',
		justifyContent: 'center',
	},
	paperImg2: {
		height: 200,
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	fitImage:{
		height: 'fit-content'
	}
});

class PrivateLables extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className="Content PrivateLables">
				<div className="component">
					<Paper className={classes.paperTitle + ' paper-component'} elevation={2}>
						<Typography variant="h5" component="h3">
							Private Lables
        		</Typography>
					</Paper>

					<Grid container spacing={2}>
						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1} elevation={2}>
								<img className="private-lables-img" src={img1} alt="momento_flajer" height="100%" align="middle" />
							</Paper>

						</Grid>
						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1} elevation={2}>
								<img className="private-lables-img" src={img2} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img3} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img4} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img5} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img6} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img18} alt="momento_classic" height="100%" align="middle" className={classes.fitImage} />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img7} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2} elevation={2}>
								<img className="private-lables-img" src={img8} alt="momento_classic" width="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1} elevation={2}>
								<img className="private-lables-img" src={img9} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img10} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img11} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img13} alt="momento_classic" height="100%" align="middle" className={classes.fitImage}/>
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img12} alt="momento_classic" height="100%" align="middle" className={classes.fitImage}/>
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img14} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg2 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img15} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img19} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img16} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img17} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>
					
						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img20} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						<Grid className={'grid'} item xs={4}>
							<Paper className={classes.paperImg1 + ' last-pl-img'} elevation={2}>
								<img className="private-lables-img" src={img21} alt="momento_classic" height="100%" align="middle" />
							</Paper>
						</Grid>

						
					</Grid>

				</div>
			</div>
		);
	}
}

PrivateLables.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivateLables);